<template>
	<div class="convention-hall page"  >
		<van-nav-bar :title="this.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="convention-item"  >
			<div class="left">
			  <van-sidebar  v-model="activeKey">
				<!-- <van-sidebar-item v-for="(v,key) in x_list" :key="key" :title="v.name" /> -->
				<div v-for="(v,key) in x_list" :key="key" class="fl" @click="onChange(key)">
					
				<span>{{v.name1[0]}}</span><br />
				<span>{{v.name1[1]}}</span>
				
				
				</div>
			
			  </van-sidebar>
			</div>	
			
			<div class="right" style="background: linear-gradient(90deg, #7e5678, #e6c3a1);">
			<van-card
			 @click="profile(v.id)" v-for="(v,key) in datalist" :key="key"
			  :thumb="v.img_url"
			  
			>
			  <template #tag>
				  <van-button type="primary" size="large"  :color="v.tag.color" :text="v.tag.name"></van-button>
				<!-- <van-tag type="primary"  size="medium" text-color ="white" :color="v.tag.color">{{v.tag.name}}</van-tag> -->
			  </template>
			  <template #title>
			  <div style="padding: 10px;">
				<div v-for="(vv,key) in v.xuanfei_name" :key="key" class="name_line">{{vv}}<br/></div>
			  </div>
			  </template>

			 </van-card>
			 
			 <!--
			  <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
				<van-grid :column-num="2">
				  <van-grid-item @click="profile(v.id)" v-for="(v,key) in datalist" :key="key">
					<van-image class="game_item_img" :src="v.img_url" style="position:relative;">
					  <template v-slot:loading>
						<van-loading type="circular"/>
					  </template>
					  
					</van-image>
					<span style="position:absolute;top:2%;">
						<van-tag type="primary" text-color="white" :color="v.tag.color">{{v.tag.name}}</van-tag>
					</span>

					<div>
						<span style="height:15px;width:100%;overflow: hidden;font-size: 11px;text-align: left;">{{v.xuanfei_name}}
						</span>
						<span style="height:14px;width:100%;overflow: hidden;font-size: 11px;text-align: left;">{{v.xuanfei_name2}}
						</span>	
					</div>
									
				  </van-grid-item>
				</van-grid>
			  </van-pull-refresh>
			  -->
			</div>
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			vod_name: '北京',
			isLoading: false,
			active: 0,
			activeKey:0,
			x_list:[],
			activeName:'',
			datalist: [
				// {
				// 	xuanfei_name: '北京 健身达人',
				// 	img_url: 'https://kk.betman2.co/storage/41/62fe1bd26d01c_image_2022-08-18_190032731.png'
				// },
				// {
				// 	xuanfei_name: '北京 学生妹',
				// 	img_url: 'https://kk.betman2.co/storage/344/630869b4bd2d6_1.jpg'
				// }
			]
		};
	},
	methods: {
		tab(name) {
					this.$http({
						method: 'get',
						url: 'xuanfeilist',
						data: { id: name }
					}).then(res => {
						this.datalist = res.data;
						
					});	
		},
		onChange(index) {
					this.vod_name=this.x_list[index].name1[0];
					if(this.x_list[index].name1[1]){
						this.vod_name=this.vod_name+this.x_list[index].name1[1];
					}
					this.$http({
						method: 'get',
						url: 'xuanfeilist',
						data: { id: this.x_list[index]['id'] }
					}).then(res => {
						this.datalist = res.data;
						
					});	
		
		},
		back() {
			this.$router.push({ path: 'Choose' });
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("reservation.refresh"));
				this.isLoading = false;
			}, 500);
		},
		profile(id) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
		},
		getxuanfeilist() {

			this.$http({
				method: 'get',
				url: 'address_class',
				data: { x_id: this.$route.query.id }
			}).then(res => {
			
				this.x_list = res.data;
				if(res.data){
					this.$http({
						method: 'get',
						url: 'xuanfeilist',
						data: { id: this.$route.query.id,all:1}
					}).then(res => {
						this.datalist = res.data;
					
					});					
				}
			});	
			
			
			
		}
	},
	created() {
		this.vod_name = this.$route.query.name;
		this.getxuanfeilist();
	}
};
</script>

<style lang='less' scoped>
::v-deep .van-button--large{
	
	width:150px;
}
::v-deep .van-sidebar-item{
	background: linear-gradient(90deg, #7e5678, #e6c3a1)!important;
	border-bottom: 5px solid #73859f;
	line-height:50px;
	padding: 30px!important;
	
}

::v-deep .van-button__text{
	font-size:30px;
}
::v-deep .van-card__tag{
	top: -1px;
}
.fl{
	background: linear-gradient(90deg, #7e5678, #e6c3a1)!important;
	text-align: center;
	padding: 15px;
	border-bottom: #7e5678 5px solid;
}
.name_line{
	margin-bottom:30px!important;
}
.van-card__thumb{
	width:50%;
	height:auto;
	object-fit: contain;
}
.van-card{
	font-size: 30px;
	color:white;
	height:400px;
	background: linear-gradient(90deg, #7e5678, #e6c3a1)!important;
	border-top: 5px solid #73859f;
}
::v-deep .van-tag{
	font-size:18px;
	position: relative;
    bottom: 11px;
	padding: 10px;
	  
}
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
}
.nav-bar{
  background: linear-gradient(
      90deg,#7e5678,#e6c3a1);
  height: 100px;

}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-grid-item__content{
	background-color: #ffffff12;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #7e5678;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #7e5678;
  font-size: 35px;
  text-align: center;
}
/deep/ .van-sidebar-item__text{
  width: 140px;
  margin-left: -25px;
}
.van-sidebar-item{
  font-size: 30px;
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
}
.van-sidebar-item--select, .van-sidebar-item--select:active {
  background-color: #f2f2f5;;
}
.convention-item{
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}
.convention-hall{
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}
.convention-item .left{
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  -webkit-overflow-scrolling: touch;
}
.convention-item .right{
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right .list-wrapper{
  padding: 20px 20px;
  min-height: 800px;
}
.convention-item .right .list-wrapper .game_item_img{
   /* width: 200px; */

   object-fit: cover;
}
.convention-item .right .list-wrapper span{
  margin-top: 10px;
  font-size: 30px;
  color: #000;
}
.convention-item .right .list-wrapper span:last-child{
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}
.van-grid-item {
  padding: 10px;
  flex-basis:51%!important;


}
::v-deep .van-grid-item__content--center {
  border-radius: 15px;
}
::v-deep .van-image__img{
  border-radius: 10px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}
::v-deep .van-image__img{
	height:390px!important;
}

</style>
