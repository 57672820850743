<template>
  <div class="container page" style="background-color: #d0abc0 !important;">
    <van-nav-bar :title="$t('setting.base_setting')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-content">
        <div @click="openHerderImg()" class="item van-hairline--bottom">
          <div class="left">{{$t("setting.avatar")}}</div>
          <div class="right">
            <!-- <img  :src="this.userInfo.header_img"> -->
			<van-uploader :after-read="afterRead" v-model="fileList"  :max-count="2" />
            <van-icon name="arrow" />
          </div>
        </div>
		
		
        <div class="item van-hairline--bottom" @click="toSetName()">
        <div class="left">{{$t("setting.real_name")}}</div>
        <div class="right">
          <span class="desc">{{this.userInfo.name ? this.userInfo.name : this.$t("setting.no_setting")}}</span>
          <van-icon name="arrow" />
        </div>
      </div>
        <div class="item van-hairline--bottom" @click="toSetSex()">
        <div class="left">{{$t("setting.sex")}}</div>
        <div class="right">
          <span class="desc">{{this.userInfo.sex !== "0" ? this.userInfo.sex === "1" ? this.$t("setting.man") :this.$t("setting.female"): this.$t("setting.unkown")}}</span>
          <van-icon name="arrow" />
        </div>
      </div>
        <div class="item van-hairline--bottom" @click="toSetBank()">
        <div class="left">{{$t("setting.bind_bank_info")}}</div>
        <div class="right">
          <span class="desc">{{this.isBank ? this.$t("setting.bindinged") :this.$t("setting.no")}}</span>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isActive:false,
      show:false,
      isBank:false,
      userInfo:{},
      fileList: [
        // { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
        // { url: 'https://cloud-image', isImage: true },
      ],
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    afterRead(file) {
	  
        let param = new FormData(); //创建form对象
        param.append('image',file.file);//通过append向form对象添加数据
        console.log(param.get('image')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
        let config = {
          headers:{'Content-Type':'multipart/form-data'} //这里是重点，需要和后台沟通好请求头，Content-Type不一定是这个值
        }; //添加请求头
        axios.post('https://c1.koreameets.com/api/member/upload',param,config)
          .then(response=>{
			  this.$http({
				method: 'post',
				data:{header_img:response.data.data},
				url: 'user_header_img'
			  }).then(res=>{
				if(res.code === 200){
				  this.getUserInfo();
				  this.$toast(res.msg);
				  this.show = false;
				}else if(res.code ===401){
				  this.$toast(res.msg);
				}
			  })
          })

	 
 
    },
    toSetName(){
      this.$router.push({path:'/Setname'});
    },
    toSetBank(){
      this.$router.push({path:'/Setbank'});
    },
    toSetSex(){
      this.$router.push({path:'/Setsex'});
    },
    openHerderImg(){
      this.show = true;
    },
    select_header_img(url){
        this.isActive = url;
    },
    check(){
      this.$http({
        method: 'post',
        data:{header_img:this.isActive},
        url: 'user_header_img'
      }).then(res=>{
        if(res.code === 200){
          this.getUserInfo();
          this.$toast(res.msg);
          this.show = false;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
		var a={url:res.data.header_img};
		  this.fileList=[];
		  this.fileList.push(a);
		  console.log( this.fileList);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.isBank = true;
          }else {
            this.isBank = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .main-content {
  padding: 0 20px;
  background-color: #b47d9c;
}
.left{
	color:white;
}
.container .main-content .item{
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
}
.container .main-content .item .right{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container .main-content .item .right img{
  width: 90px;
}
.container .main-content .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.container .main-content .item .right .desc-cell-number, .container .main-content .item .right .desc{
  font-size: 30px;
  /* font-weight: 700; */
  color: white;
}
.avatarbox{
  padding: 15px;
  color: #000;
  height: 81%;
  background-color: #fff;
}
.avatarbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}

.avatarbox .content .van-image{
  width: 105px;
  height: 105px;
  margin: 2.5%;
  border-radius: 50%;
}
.avatarbox .content{
  padding-bottom: 10px;
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.avatarbox .content .choose{
  width: 95px;
  height: 95px;
  border: 6px solid #e6c3a1;
}
</style>
