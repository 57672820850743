<template>
	<div class="page">
		<van-nav-bar :title="$t('concubine.concubine')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<p class="name">{{ this.xuanfeidata.xuanfei_name }}</p>
			<p class="title">{{ this.xuanfeidata.vo_title }}</p>
			<van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			<van-button round="true" @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">{{$t("goyh")}}</van-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: [],
		    userInfo:{
		    },
		};
	},
	methods: {
		back() {
			this.$router.go(-1);
			// this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
			});
		},
		yuyue() {
			if(this.userInfo.vip>this.xuanfeidata.vip.id){
				this.$router.push({ path: 'ServiceOnline'});
				
			}else{
				this.$toast(this.$t('vipbugou')+this.xuanfeidata.vip.name);
			}
			

		},
		getUserInfo(){
		  this.$http({
			method: 'get',
			url: 'user_info'
		  }).then(res=>{
			if(res.code === 200){
				this.userInfo = res.data;
				this.menu_top = 15;
				if(this.userInfo.status !== 1){
				  this.$toast(this.$t("video.account_out"));
				  localStorage.clear()
				  this.$router.push({path:'/Login'})
				}
			}else if(res.code ===401){
			  this.$toast(res.msg);
			}
		  })
		},
	},
	created() {
		this.getxuanfeidata();
		if(localStorage.getItem('token')){
			this.getUserInfo();
		}else {
		  this.userInfo.username = this.$t("setting.log_reg");
		  this.userInfo.ip = this.$t("setting.more_service");
		  this.userInfo.header_img = "img/mine/avatar.png";
		}
	}
};
</script>

<style>
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
}
.name {
	font-size: 1.125rem;
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
</style>
