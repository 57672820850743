<template>
  <div class="home-container">
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper"  style="background: linear-gradient(270deg, #e6c3a1, #7e5678);" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
	  
      <div class="notice-bar" style="background: #b47d9c;">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#b47d9c"
			
			
            color="white"
            :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div>
	  
      <div class="hot-game">
<!--        <div class="hot-title-div">
          <div>
            <span>{{$t('index.task')}}</span></div>
          <div @click="gotoMenu('/Game')">
            <span>{{$t('index.more')}}</span>
            <van-icon name="arrow" color="#2b333f" />
          </div>
        </div> -->
		
        <div class="hot-items-div">
          <van-grid :border = false :column-num="4" :icon-size="20">
            <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{v.name}}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
	  
	  
	  
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="hot-recommend">
     <!--     <div class="hot-title-div">
            <div>
              <span>{{$t('index.hot')}}</span></div>
            <div>
              <span @click="gotoMenu('/Video')">{{$t('index.more')}}</span>
              <van-icon name="arrow" color="#2b333f" />
            </div>
          </div> -->
          <div class="movie_list_0">
           <!-- <swiper class="movie_swiper" :options="movielistSwiperOption" 
			>
              <swiper-slide v-for="(v,key) in movielist_0" :key="key" >
                <van-image class="movie_cover" @click="toPlayVideo(v.id)"  round :src="v.cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper> -->
			
			<div class="xiaofei">
         <!--   <swiper class="movie_swiper"   :options="movielistSwiperOption2" 
			>
              <swiper-slide v-for="(v,key) in movielist_2" :key="key" >
                <van-image class="movie_cover1"   @click="toXiaoFei(v.id)"  round :src="v.img_url">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper> -->			
			</div>
			
          </div>
		  
		  
<!--          <div class="hot-title-div">
            <div>
              <span>{{$t('index.more')}}</span></div>
            <div @click="gotoMenu('/Video')">
              <span>{{$t('index.recmonmand')}}</span>
              <van-icon name="arrow" size="25" color="#2b333f" />
            </div>
          </div> -->
		  
		  <div  class="noticeContent">
			<div  class="sm">
				<div>
					
					<div  class="left">
						<p> {{$t('xsjmt')}}</p>
						<p  style="text-align: center;">{{$t('yule')}}</p>
						<p  style="text-align: center;">{{$t('fuli')}}</p>
					</div>			
					<div  class="right">
						<p >{{$t('wlqb')}}</p>
					
					</div>			
				</div>
				<span>
					{{$t('ptxz')}}
				</span>
				
				
				
			</div>
			
			<div class="navs">
				<nav >
					<a class="active">{{$t('xsjmtzy')}}</a>
					
				</nav>				
				
				
			</div>	
				
			<div  class="item" >
				<ul>
					<li v-for="(v,key) in movielist_2" :key="key"  @click="profile(v.id)">
					<i >{{v.tag.name}}</i>
					<div  class="left">
						<p>ID:{{v.id}}</p>
						<div  class="ziliao">
							<span v-for="(vv,kk) in v.xuanfei_name" :key="kk">{{vv}}</span>

						</div>
					</div>
					<div class="right">
						<div  class="avatar">
							<img :src="v.img_url" alt="">
						</div>						
					</div>						
					</li>					

				</ul>				
				
				
			</div>					
					
	
		  </div>		  	
		  
          <!-- <div class="movie_list_1"> -->
            <!-- <div class="movie-list-item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)"> -->
            <!--  <van-image class="cover_img"  round :src="v.cover">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image> -->
             <!-- <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{v.title}}</span>
                  <span>{{$t('video.play')}}:{{v.count}}</span>
                </div>
              </div> -->
            <!-- </div> -->
            <!-- <div class="hot-recommend-more" @click="gotoMenu('/Video')">{{$t('index.more')}}</div> -->
          <!-- </div> -->
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData:[],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
	  movielist_2: [{},{},{},{}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
		// autoplay:true,
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      movielistSwiperOption2: {
        slidesPerView: 'auto',
		autoplay:true,
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      }
    };
  },
  methods:{
	profile(id) {
		this.$router.push({ path: '/profile?id=' + id });
	},
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    toXiaoFei(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/profile?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("刷新成功");
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
		this.getMovieList_2(this.basicData);//获取首页视频1
		
		
      })

    },
    getNotice(data){
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getMovieList_2(data){
		console.log(data.movielist_2);
      this.movielist_2 = data.movielist_2
    },
    getBanner(data){
      this.banners = data.banners;
    }
  },
  mounted () {

  },
  created() {
    this.getBasicConfig();
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.item ul li i{
    position: absolute;
    right: -2.333vw;
    top: 2.667vw;
    background: #ff6056;
    font-size: 2.133vw;
    padding: .533vw 2.667vw;
    font-style: normal;
    transform: rotate(45deg);
    color: #e7e7e7;
}
.noticeContent{
    padding: 2.667vw;
}
.navs>nav a.active{
    background: linear-gradient(#fde3ca, #e7b486);
    color: #8a5623;
}
.item ul li .left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.item ul li .left p{
    background: linear-gradient(90deg, #efcba7, #dcab76);
    color: #8d5825;
    display: inline-block;
    font-size: 2.6vw;
    font-weight: 600;
    padding: 2.667vw 2.4vw;
    border-radius: 1.333vw;
    margin-bottom: 1.333vw;
    margin-top: 2.133vw;
}
.item ul li .left .ziliao{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 3.733vw;
    margin-left: 1.333vw;
}
.item ul li .left .ziliao span {
    padding: .4vw 0;
    color: #e8e8e8;
}
.navs>nav a{
    font-size: 2.867vw;
    padding: 1.333vw 2vw;
    border-radius: 6.667vw;
    margin-right: 1.867vw;
    background: linear-gradient(90deg, #5b606c, #2b2b2b);
    color: #fff;
    margin-bottom: 1.333vw;
}
.item ul li .right .avatar img{
    width: 100%;
}
.item ul li .right .avatar{
    width: 28vw;
    height: 28vw;
    border-radius: 50%;
    border: .267vw solid #5c534b;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.item ul li .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.item ul li:after, .item ul li:before {
    content: "";
    position: absolute;
    top: -100%;
    height: 400%;
    background: linear-gradient(90deg, hsla(0, 18%, 88%, .1), transparent);
    transform: rotate(50deg);
}
.item ul li:after {
    width: 13.333vw;
    left: 40%;
}
.item ul li:before {
    width: 8vw;
    left: 1%;
}
.item ul li:after, .item ul li:before {
    content: "";
    position: absolute;
    top: -100%;
    height: 400%;
    background: linear-gradient(90deg, hsla(0, 18%, 88%, .1), transparent);
    transform: rotate(50deg);
}
.item ul li{
    float: left;
    width: 100%;
    margin-top: 1.333vw;
    min-height: 1.867vw;
    background: linear-gradient(270deg, #b47d9c, #7e5678);
    position: relative;
    overflow: hidden;
    margin-bottom: 1.333vw;
    border-radius: 1.333vw;
    border: 1px solid #7e6a56;
    padding: 1.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sm {
    width: 100%;
    min-height: 1rem;
    border-radius: 1.333vw;
    border: 1px solid #766350;
    margin: 1.333vw 0;
    background: linear-gradient(270deg, #b47d9c, #7e5678);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.333vw;
    position: relative;
    overflow: hidden;
}
.sm>div{
	position: relative;
	z-index: 99;
	display: flex;
}
.sm:before {
    left: -4%;
}
.sm:after {
    left: -35%;
    z-index: 0;
}
*, :after, :before {
    box-sizing: border-box;
}
.sm:after, .sm:before {
    content: "";
    position: absolute;
    width: 137.4vw;
    height: 19.2vw;
    background: linear-gradient(0deg, rgba(222, 213, 162, .29), transparent);
    transform: rotate(139deg);
}
.sm:after, .sm:before {
    content: "";
    position: absolute;
    width: 137.4vw;
    height: 19.2vw;
    background: linear-gradient(0deg, rgba(222, 213, 162, .29), transparent);
    transform: rotate(139deg);
}
.sm .left{
    width: 32%;
    position: relative;
    z-index: 99;
}
.sm .left:before {
    content: "";
    position: absolute;
    width: .02rem;
    height: 100%;
    background: #f6d5a1;
    right: 0;
    top: 0;
}
.sm .left p{
    font-weight: 1000;
    background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 4.267vw;
    text-align: center;
    margin-top: 3vw;
}
.sm .right{
    flex: 1;
    padding-left: 1.333vw;
}
.sm .right p{
    color: #dadada;
    font-size: 3vw;
    line-height: 6.4vw;
}
.sm .left span, .sm>span {
    color: #ddb285;
    font-size: 3vw;
    padding-top: 1.333vw;
    text-align: center;
}
.sm>span{
    line-height: 5.067vw;
}
.sm:after {
    left: -35%;
    z-index: 0;
}
.sm:after, .sm:before {
    content: "";
    position: absolute;
    width: 137.4vw;
    height: 19.2vw;
    background: linear-gradient(0deg, rgba(222, 213, 162, .29), transparent);
    transform: rotate(139deg);
}



.linear-bg{
  height: 200px;
  background: linear-gradient(
      270deg,#e6c3a1,#7e5678);
}

.xiaofei{

::v-deep  .swiper-wrapper{
	width:43%;
}
::v-deep .van-image--round img {
    border-radius: inherit;
    object-fit: cover;
}	
}
.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  /* background-color: #ffffff; */
  background-color: #d0abc0!important;
  
  
  height: 100%;
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}
::v-deep .van-pull-refresh{
	background-color: #d0abc0 !important;
	padding-bottom: 100px;
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:40px;
}
.notice-swipe{
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner{
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    /* background-color: #ffffff; */
	background-color: #d0abc0!important;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height:  100%;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div> div:first-child{
  width: 430px;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span{
  font-size: 20px;
  color: #2b333f;
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
}

.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #fff;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 25px;
  color: #2b333f;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}
.hot-recommend{
  width: 100%;
  flex: 1;
  background-color: #d0abc0!important;
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0{
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:550px;
  height:330px;
}
.movie_cover1{
  border-radius: 10px;
  width:90%;
  /* text-align: center; */
  /* margin: 0 auto; */
  height:300px;
  
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span{
  font-size: 30px;
}
.movie_list_1{
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img{
  border-radius: 10px;
  width:335px;
  height:290px;
}
.home-scroll{
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item{
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;

}
.movie_list_1 .movie-list-item:nth-child(odd){
  margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right:110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #2b333f;
  font-size: 30px;
}
.hot-items-div .game_item_img{
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}
</style>
