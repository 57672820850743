<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('my.account_detail')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
	<van-tabs v-model="active">
	  <van-tab :title="$t('my.account_detail')">
		  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			  <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
			  <div v-else class="item_list" v-for="(v,key) in list" :key="key">
				<div class="topInfo">
				  <span v-if="v.status === 2" style="color: #07c160">{{v.status_text}}</span>
				  <span v-else-if="v.status === 4" style="color: #07c160">{{v.status_text}}</span>
				  <span v-else >{{v.status_text}}</span>
				  <span>{{$t("withdraw.money")}}：{{v.money}}</span>
				</div>
				<div class="desc">
				  <span>{{$t("withdraw.desc")}}：{{v.desc}}</span>
				</div>
				<div class="time">
				  <span>{{$t("withdraw.submit_time")}}：{{v.create_time}}</span>
				</div>
				<div class="time">
				  <span>{{$t("withdraw.check_time")}}：{{v.update_time}}</span>
				</div>
			  </div>
		  </van-pull-refresh>		  
		  
		  
		  
		  
	  </van-tab>
	  <van-tab :title="$t('withdraw.with_record')">
		  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			  <van-empty v-if="tx_list.length === 0" :description="$t('withdraw.empty_data')" />
			  <div v-else class="item_list" v-for="(v,key) in tx_list" :key="key">
				<div class="topInfo">
				  <span v-if="v.status === 2" style="color: #07c160">{{v.status_text}}</span>
				  <span v-else-if="v.status === 4" style="color: #07c160">{{v.status_text}}</span>
				  <span v-else >{{v.status_text}}</span>
				  <span>{{$t("withdraw.money")}}：-{{v.money}}</span>
				</div>
				<div class="desc">
				  <span>{{$t("withdraw.desc")}}：{{v.desc}}</span>
				</div>
				<div class="time">
				  <span>{{$t("withdraw.submit_time")}}：{{v.create_time}}</span>
				</div>
				<div class="time">
				  <span>{{$t("withdraw.check_time")}}：{{v.update_time}}</span>
				</div>
			  </div>
		  </van-pull-refresh>		  
		  
		  
		  
	  </van-tab>
	  <van-tab :title="$t('my.task_record')">
		  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<van-empty v-if="game_list.length === 0" :description="$t('withdraw.empty_data')" />
			<div v-else class="item_list" v-for="(v,key) in game_list" :key="key">
			  <div class="lottery_info">
				<van-image class="cover" :src="v.ico">
				  <template v-slot:loading>
					<van-loading type="spinner"/>
				  </template>
				</van-image>
				<span class="period-number">{{v.expect}}</span>
				<span class="period-number">{{v.name}}</span>
			  </div>
			  <div class="recent">
				<div class="kuaisan-ball left">
				  <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[0] + '.png' " >
					<template v-slot:loading>
					  <van-loading type="spinner"/>
					</template>
				  </van-image>
				  <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' " >
					<template v-slot:loading>
					  <van-loading type="spinner"/>
					</template>
				  </van-image>
				  <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' " >
					<template v-slot:loading>
					  <van-loading type="spinner"/>
					</template>
				  </van-image>
				  <span class="res-des middle">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span>
				  <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ?$t("reservation.big") : $t("reservation.small")}}</span>
				  <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t("reservation.double") : $t("reservation.single")}}</span>
				</div>
			  </div>
			  <div class="topInfo">
				<span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
				<span v-else>{{v.status_text}}</span>
				<span>{{$t("reservation.money")}}：{{v.money}}</span>
			  </div>
		   <!--   <div class="topInfo">
				<span v-if="v.is_win === 1" style="color: #07c160">{{v.win_text}}</span>
				<span v-else >{{v.win_text}}</span>
				<span>金额：{{v.profit}}</span>
			  </div> -->
			  <!-- <div class="topInfo">
				<span>任务类型：</span>
				<span>{{v.type}}</span>
			  </div> -->
			  <div class="time">
				<span>{{$t("reservation.order_time")}}：{{v.create_time}}</span>
			  </div>
			  <div class="time">
				<span>{{$t("reservation.settle_time")}}：{{v.update_time}}</span>
			  </div>
			</div>
		  </van-pull-refresh>		  
		  
		  
		  
		  
		  
	  </van-tab>
	  <!-- <van-tab title="标签 4">内容 4</van-tab> -->
	</van-tabs>
      
	  
	  

    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[],
	  active: 0,
	  tx_list:[],
	  game_list:[]
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    getUserWithdrawList(){
		
      this.$http({
        method: 'get',
        url: 'user_get_recharge_list'
      }).then(res=>{
        if(res.code === 200){
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
	  
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_list'
      }).then(res=>{
        if(res.code === 200){
          this.tx_list = res.data;
        }
      })	  
	  
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res=>{
        if(res.code === 200){
			console.log(res.data)
          this.game_list = res.data;
        }
      })	  
	  
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserWithdrawList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #d0abc0;
  height: 100%;
  padding: 0 10px;
}
::v-deep .van-tabs__nav{
	background-color: #ffffff00;
}
::v-deep .van-tab--active{
	color:white;
}

.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	color:white;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.recent {
  display: flex;
  align-items: center;
  height: 100px;
}
.kuaisan-ball .left{
  justify-content: flex-start;
}
.kuaisan-ball{
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img{
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #000;
}
.kuaisan-ball .res-des.middle{
  width: 15%;
  font-size: 35px;
}
</style>
